import React, { useState } from 'react';
import { LeftSide } from '.';
// import "react-toastify/dist/ReactToastify.css";
import 'react-toastify/dist/ReactToastify.min.css';
import TopNavTS from './TopNavTS';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [navToggle, setNavToggle] = useState(false);
	return (
		<div className="d-flex">
			
				<LeftSide nav={navToggle} />
			
			<main className="w-100" style={{ maxWidth: 'calc(100vw - 5em)',overflowY:'scroll',height:'100vh'}} >
				<TopNavTS toggleSideMenu={() => setNavToggle(!navToggle)} />
				<div className="px-4">
					{/* <ToastContainer limit={1} /> */}
					{children}
				</div>
			</main>
			{/* <RightSideBar /> */}
		</div>
	);
};

export default Layout;
