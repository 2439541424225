import { createApi } from '@reduxjs/toolkit/query/react';
import { IApiResponse, IPaginate } from '../../interfaces/common';
import { IHmo, IHmoAdmissionList } from '../../interfaces/hmo';
import { baseQuery } from './common';

export const hmoApi = createApi({
	reducerPath: 'hmos',
	baseQuery: baseQuery,
	tagTypes: ['Hmos', 'Hmo', 'Template'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		getHmos: builder.query<IApiResponse<IPaginate<IHmo[]>>, { pagination: { page?: number; limit?: number }; searchTerm?: string }>({
			query: ({ pagination: { page = 1, limit = 10 }, searchTerm }) =>
				`/hmo?page=${page}&limit=${limit}${searchTerm ? `&searchTerm=${searchTerm}` : ''}`,
			providesTags: [{ type: 'Hmos', id: 'LIST' }],
		}),

		getAllHmos: builder.query<IApiResponse<IPaginate<IHmo[]>>, void>({
			query: () => `/hmo?limit=${'All'}`,
			providesTags: [{ type: 'Hmos', id: 'LIST' }],
		}),

		getHmo: builder.query<IApiResponse<IHmo>, string>({
			query: (id) => `/hmo/${id}`,
			providesTags: (result, error, arg) => [{ type: 'Hmo', id: arg }],
		}),
		// Modify the others to follow this format
		getHMOAdmissionList: builder.query<
			IApiResponse<IPaginate<IHmoAdmissionList[]>>,
			{ id: string; filterBy: string; searchTerm: string; pagination: { page?: number; limit?: number } }
		>({
			query: ({ id, filterBy, pagination: { page = 1, limit = 10 } }) => `/hmo/${id}/price-list/Admission`,
			providesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }],
		}),
		// This should be the url but the endpoint currently breaks when page and limit are supplied
		// Tell Favour to fix it
		// `/hmo/${id}/price-list/Admission?page=${page}&limit=${limit}`,

		getHMOBloodList: builder.query<
			IApiResponse<IPaginate<IHmo[]>>,
			{ id: string; filterBy: string; searchTerm: string; pagination: { page?: number; limit?: number } }
		>({
			query: ({ id, filterBy }) => `/hmo/${id}/price-list/Blood`,
			providesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }],
		}),
		getHMOLaboratoryList: builder.query<
			IApiResponse<IPaginate<IHmo[]>>,
			{ id: string; filterBy: string; searchTerm: string; pagination: { page?: number; limit?: number } }
		>({
			query: ({ id, filterBy }) => `/hmo/${id}/price-list/Laboratory`,
			providesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }],
		}),
		getHMORadiologyList: builder.query<
			IApiResponse<IPaginate<IHmo[]>>,
			{ id: string; filterBy: string; searchTerm: string; pagination: { page?: number; limit?: number } }
		>({
			query: ({ id, filterBy }) => `/hmo/${id}/price-list/Radiology`,
			providesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }],
		}),
		getHMOMedicationList: builder.query<
			IApiResponse<IPaginate<IHmo[]>>,
			{ id: string; filterBy: string; searchTerm: string; pagination: { page?: number; limit?: number } }
		>({
			query: ({ id, filterBy }) => `/hmo/${id}/price-list/Medication`,
			providesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }],
		}),
		getHMOProcedureList: builder.query<
			IApiResponse<IPaginate<IHmo[]>>,
			{ id: string; filterBy: string; searchTerm: string; pagination: { page?: number; limit?: number } }
		>({
			query: ({ id, filterBy }) => `/hmo/${id}/price-list/Procedure`,
			providesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }],
		}),
		getHMOProfessionalServiceList: builder.query<
			IApiResponse<IPaginate<IHmo[]>>,
			{ id: string; filterBy: string; searchTerm: string; pagination: { page?: number; limit?: number } }
		>({
			query: ({ id, filterBy }) => `/hmo/${id}/price-list/ProfessionalService`,
			providesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }],
		}),
		getHMOOtherServiceList: builder.query<
			IApiResponse<IPaginate<IHmo[]>>,
			{ id: string; filterBy: string; searchTerm: string; pagination: { page?: number; limit?: number } }
		>({
			query: ({ id, filterBy }) => `/hmo/${id}/price-list/OtherService`,
			providesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }],
		}),
		getHmoExcelTemplate: builder.query<IApiResponse<{ url: string }>, 'url' | 'file'>({
			query: (type: 'url' | 'file' = 'url') => `/hmo/template/?type=${type}`,
			providesTags: (result, error, arg) => [{ type: 'Template', id: arg }],
		}),
		addHmo: builder.mutation<IHmo, Partial<IHmo>>({
			query: (body) => ({
				url: `hmo`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Hmos', 'Hmo'],
		}),
		updateHmo: builder.mutation<IHmo, { id: string; data: Partial<IHmo> }>({
			query({ id, data }) {
				return {
					url: `hmo/${id}`,
					method: 'PATCH',
					body: data,
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }, 'Hmos'],
		}),
		deleteHmo: builder.mutation<IHmo, Partial<IHmo>>({
			query: ({ id }) => ({
				url: `/hmo/${id}`,
				method: 'DELETE',
				body: id,
			}),
			invalidatesTags: ['Hmos', 'Hmo'],
		}),
		generateAdmissionPriceList: builder.mutation<
			IHmo,
			{
				id: string;
			}
		>({
			query({ id }) {
				return {
					url: `/hmo/${id}/price-list/Admission`,
					method: 'POST',
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }, 'Hmos'],
		}),
		generateBloodPriceList: builder.mutation<
			IHmo,
			{
				id: string;
			}
		>({
			query({ id }) {
				return {
					url: `/hmo/${id}/price-list/Blood`,
					method: 'POST',
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }, 'Hmos'],
		}),
		generateLaboratoryPriceList: builder.mutation<
			IHmo,
			{
				id: string;
			}
		>({
			query({ id }) {
				return {
					url: `/hmo/${id}/price-list/Laboratory`,
					method: 'POST',
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }, 'Hmos'],
		}),
		generateRadiologyPriceList: builder.mutation<
			IHmo,
			{
				id: string;
			}
		>({
			query({ id }) {
				return {
					url: `/hmo/${id}/price-list/Radiology`,
					method: 'POST',
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }, 'Hmos'],
		}),
		generateMedicationPriceList: builder.mutation<
			IHmo,
			{
				id: string;
			}
		>({
			query({ id }) {
				return {
					url: `/hmo/${id}/price-list/Medication`,
					method: 'POST',
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }, 'Hmos'],
		}),
		generateProcedurePriceList: builder.mutation<
			IHmo,
			{
				id: string;
			}
		>({
			query({ id }) {
				return {
					url: `/hmo/${id}/price-list/Procedure`,
					method: 'POST',
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }, 'Hmos'],
		}),
		generateProfessionalServicePriceList: builder.mutation<
			IHmo,
			{
				id: string;
			}
		>({
			query({ id }) {
				return {
					url: `/hmo/${id}/price-list/ProfessionalService`,
					method: 'POST',
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }, 'Hmos'],
		}),
		generateOtherServicePriceList: builder.mutation<
			IHmo,
			{
				id: string;
			}
		>({
			query({ id }) {
				return {
					url: `/hmo/${id}/price-list/OtherService`,
					method: 'POST',
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }, 'Hmos'],
		}),
		completeListGeneration: builder.mutation<
			IHmo,
			{
				id: string;
			}
		>({
			query({ id }) {
				return {
					url: `/hmo/${id}/price-list/complete`,
					method: 'PATCH',
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Hmo', id: arg.id }, 'Hmos'],
		}),
	}),
});

export const {
	useGetHmosQuery,
	useGetHmoExcelTemplateQuery,
	useGetHmoQuery,
	useAddHmoMutation,
	useUpdateHmoMutation,
	useDeleteHmoMutation,
	useGetAllHmosQuery,
	useGetHMOAdmissionListQuery,
	useGetHMOBloodListQuery,
	useGetHMOLaboratoryListQuery,
	useGetHMORadiologyListQuery,
	useGetHMOMedicationListQuery,
	useGetHMOProcedureListQuery,
	useGetHMOProfessionalServiceListQuery,
	useGetHMOOtherServiceListQuery,
	useGenerateAdmissionPriceListMutation,
	useGenerateBloodPriceListMutation,
	useGenerateLaboratoryPriceListMutation,
	useGenerateRadiologyPriceListMutation,
	useGenerateMedicationPriceListMutation,
	useGenerateProcedurePriceListMutation,
	useGenerateProfessionalServicePriceListMutation,
	useGenerateOtherServicePriceListMutation,
	useCompleteListGenerationMutation,
} = hmoApi;
